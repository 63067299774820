#app-main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#main-wrapper {
  background-color: rgba(0, 255, 0, 0.164);
  box-shadow: 0 0 100px darkgreen;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

#status-displayer {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 50px;
}

#firstLoadPopUp {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#firstLoadPopUp-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 50%;
  justify-content: space-around;
}

#firstLoadPopUp-center>p {
  font-size: 26px;
  color: lightgreen;
  padding-left: 10px;
  padding-right: 10px;
}

#firstLoadPopUp-center>button {
  font-size: 26px;
  color: lightgreen;
  padding: 10px;
  background-color: black;
  border: 2px solid darkgreen;
  cursor: pointer;
}

@media (max-width: 600px) {
  #firstLoadPopUp-center {
    height: 90vh;
    width: 90%;
  }
}

.status-text {
  font-size: 22px;
}

::-webkit-scrollbar {
  background: lightgreen;
}

::-webkit-scrollbar-thumb {
  background: darkgreen;
}

#room-creation-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

p {
  margin: 0;
  padding: 0;
}

img {
  margin: 0;
  padding: 0;
  border: none;
}

label {
  color: lightgreen;
  font-size: 28px;
  margin-bottom: 10px;
}

h1 {
  color: lightgreen;
  font-size: calc(0.75vh + 0.75vw);
}

input {
  width: 100%;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.home-link {
  margin-top: 4vh;
  width: 80%;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px darkgreen;
  border-radius: 10px;
  border: darkgreen solid 2px;
  position: relative;
}

@media (max-width: 600px) {
  .home-link {
    width: 100%;
  }
}

.home-buttons {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.home-buttons>p {
  color: lightgreen;
  font-size: calc(2vh + 2vw);
}

.title {
  margin-top: 30px;
}

#title1,
#title2,
#title3,
#title4,
#title5,
#title6,
#howto1,
#howto2,
#howto3,
#howto4,
#howto5,
#howto6 {
  animation-name: text-appear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

#title1 {
  animation-delay: 0.2s;
}

#title2 {
  animation-delay: 1s;
}

#title3 {
  animation-delay: 1.5s;
}

#title4 {
  animation-delay: 2s;
}

#title5 {
  animation-delay: 2.5s;
}

#title6 {
  animation-delay: 3s;
}

#howto1 {
  animation-delay: 0s;
}

#howto2 {
  animation-delay: 1.5s;
}

#howto3 {
  animation-delay: 3s;
}

#howto4 {
  animation-delay: 4.5s;
}

#howto5 {
  animation-delay: 6s;
}

#howto6 {
  animation-delay: 7.5s;
}

@keyframes text-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/********************************************************

            CREATE ROOM

********************************************************/
.create-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 4vh;
  margin-bottom: 4vh;
  border-radius: 10px;
  box-shadow: 0 0 20px darkgreen;
  border: darkgreen solid 2px;
}

#room-access-select {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creation-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 30px;
  margin-bottom: 40px;
}

.button-group>button,
.styled-button {
  background-color: transparent;
  box-shadow: 0 0 20px darkgreen;
  border-radius: 10px;
  border: darkgreen solid 2px;
  font-size: 28px;
  color: lightgreen;
}

#password,
#nickName,
#roomName,
#search {
  background-color: transparent;
  box-shadow: 0 0 20px darkgreen;
  border-radius: 10px;
  border: darkgreen solid 2px;
  font-size: 24px;
  color: lightgreen;
  margin-bottom: 30px;
}

#create-room-btn {
  border-radius: 8px;
  box-shadow: 0 0 20px darkgreen;
  border: darkgreen solid 2px;
  background-color: transparent;
  color: lightgreen;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 30px;
}

/********************************************************

                HOW TO

********************************************************/

.howto>p {
  color: lightgreen;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 3vh;
  font-size: calc(0.75vh + 0.75vw);
  line-height: 4vh;
}

.howto {
  box-shadow: 0 0 20px darkgreen;
  border-radius: 10px;
  border: darkgreen solid 2px;
  background-color: rgba(0, 0, 0, 0.8);
  height: fit-content;
  margin-top: 40px;
  border-radius: 10px;
  width: 80%;
  margin-bottom: 100px;
}

/* style={{
  backgroundColor: "rgba(0,0,0,0.8)",
  width: width,
  height: "80%",
  marginTop: "4vh",
  marginBottom: "4vh",
  borderRadius: "10px"
}} */

/* QUERIES */

@media (max-width: 600px){
  .howto {
    width: 100%;
  }
}

/********************************************************

                JOIN

********************************************************/

.back-enter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#join-wrapper{
  position: relative;
  width: 80%;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.8);
  margin-top:  4vh;
  margin-bottom: 4vh;
  border-radius: 10px;
  box-shadow: 0 0 20px darkgreen;
  border: darkgreen solid 2px;
}

/* QUERIES */

@media (max-width: 600px){
  #join-wrapper{
    width: 100%;
    height: 60vh;
  }
}

/********************************************************

                Room

********************************************************/

/* .message{
  animation-name: message-appear;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes message-appear{
  from{opacity: 0;}
  to{opacity: 1;}
} */

#msg-displayer {
  height: 550px;
  max-height: 55vh;
  width: 100%;
  overflow: auto;
  scrollbar-color: darkgreen lightgreen;
  overflow-x: hidden;
  scrollbar-width: thin;
  margin-top: 5px;
}

.confirm-text {
  color: lightgreen;
  font-size: 52px;
  margin-bottom: 3vh;
}

.msg-anim-enter {
  opacity: 0;
  transform: scale(0);
}

.msg-anim-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 400ms ease-in, transform 200ms ease-in;
}

#room-title {
  color: lightgreen;
  font-size: 26px;
  margin: 0;
  padding: 0;
  margin-top: 0.5%;
  margin-bottom: 0.5;
}

#room-function-selector {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 0.5;
}

#message-sender {
  font-size: 16px;
}

#message-content {
  font-size: 20px;
}

#typing-div {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 80px;
  margin-top: 5px;
  margin-bottom: 20px;
}

#typing {
  font-size: 20px;
}

#send-btn {
  width: 20%;
}

/********************************************************

                Footer

********************************************************/

#footer {
  height: 80px;
  width: 100%;
  background-color: black;
}


/* QUERIES */

@media (max-width: 1000px) {
  #main-wrapper {
    width: 90%;
  }

  .status-text {
    font-size: 16px;
  }

  label {
    color: lightgreen;
    font-size: 24px;
  }

  h1 {
    color: lightgreen;
    font-size: calc(0.75vh + 0.75vw);
  }

  .home-buttons>p {
    color: lightgreen;
    font-size: calc(2vh + 2vw);
  }

  #create-room-btn {
    font-size: 22px;
  }

  .button-group>button,
  .styled-button {
    background-color: transparent;
    box-shadow: 0 0 20px darkgreen;
    border-radius: 10px;
    border: darkgreen solid 2px;
    font-size: 20px;
    color: lightgreen;
  }

  #password,
  #nickName,
  #roomName,
  #search {
    background-color: transparent;
    box-shadow: 0 0 20px darkgreen;
    border-radius: 10px;
    border: darkgreen solid 2px;
    font-size: 20px;
    color: lightgreen;
  }

  .howto>p {
    color: lightgreen;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 3vh;
    font-size: calc(0.75vh + 0.75vw);
    line-height: 4vh;
  }
}

@media (max-width: 600px) {
  #main-wrapper {
    width: 95%;
  }

  .status-text {
    font-size: 14px;
  }

  label {
    color: lightgreen;
    font-size: 20px;
  }

  h1 {
    color: lightgreen;
    font-size: calc(0.75vh + 0.75vw);
  }

  .home-buttons>p {
    color: lightgreen;
    font-size: calc(2vh + 2vw);
  }

  .button-group>button,
  .styled-button {
    background-color: transparent;
    box-shadow: 0 0 20px darkgreen;
    border-radius: 10px;
    border: darkgreen solid 2px;
    font-size: 16px;
    color: lightgreen;
  }

  #password,
  #nickName,
  #roomName,
  #search {
    background-color: transparent;
    box-shadow: 0 0 20px darkgreen;
    border-radius: 10px;
    border: darkgreen solid 2px;
    font-size: 16px;
    color: lightgreen;
  }

  #create-room-btn {
    font-size: 18px;
  }

  #room-title {
    font-size: 20px;
  }

  #typing-div {
    height: 50px;
  }

  #msg-displayer {
    height: 450px;
    max-height: 45vh;
  }

  .howto>p {
    color: lightgreen;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 14px;
    font-size: 14px;
    line-height: 3vh;
  }
}